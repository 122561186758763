<script setup lang="ts">
import type { PostLayoutProps } from '@/types'

const props = defineProps<PostLayoutProps>()

const { infiniteScroll } = useAppConfig()

const posts = ref([props])

const next = ref(props.next)
const previous = ref(props.previous)

const { refresh, data } = await useFetch('/api/legacy/node', {
  query: {
    uri: computed(() => previous.value.uri),
  },
  immediate: false,
})

const { start, finish } = useLoadingIndicator()

const { isCrawler } = useDevice()

async function load() {
  if (isCrawler) return false

  if (!infiniteScroll.enabled)
    return false

  if (posts.value.length === infiniteScroll.perPage)
    return false

  start()

  await refresh()

  next.value = data.value.next
  previous.value = data.value.previous

  posts.value.push(data.value)

  finish()
}
</script>

<template>
  <div>
    <PostNextPrev
      :next="previous"
      :previous="next"
    />

    <div
      v-for="post in posts"
      :key="post.id"
    >
      <NuxtLayout
        name="post"
        v-bind="{ ...post }"
        @footer-visible="load()"
      />
    </div>
  </div>
</template>
